'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import DropdownIcon from '@/assets/Icons/Homepage/right-fill-dropdown-icon.svg';
import AppStoreImage from '@/assets/Logo/app-store.png';
import Logo from '@/assets/Logo/medway-logo.png';
import PlayStoreImage from '@/assets/Logo/play-store.png';

import WhyMedway from './navbar/WhyMedway';

const Navbar = () => {
  const [dropdown, setDropdown] = useState(null);

  const toggleDropdown = (menu: any) => {
    setDropdown(dropdown === menu ? null : menu);
  };

  const menuItems = [
    {
      label: 'Why Medway',
      type: 'button',
      key: 'why-medway',
      onClick: () => toggleDropdown('why-medway'),
    },
    { label: 'Courses', type: 'link', href: '/courses' },
    {
      label: 'Batch',
      type: 'button',
      key: 'batch',
      onClick: () => toggleDropdown('batch'),
      dropdown: [
        { label: 'Sambhav Batch', href: '/batches/sambhav-batch' },
        { label: 'Live Class Notes', href: '/batches/live-class-notes' },
        { label: 'Sawaal Bemisaal', href: '/batches/sawaal-bemisaal' },
      ],
    },
    {
      label: 'Plans',
      type: 'link',
      href: '/plans',
      // onClick: () => toggleDropdown('plans'),
      // dropdown: [
      //   { label: 'Basic Plan', href: '/plan-basic' },
      //   { label: 'Premium Plan', href: '/plan-premium' },
      // ],
    },
    { label: 'Faculty', type: 'link', href: '/our-faculty' },
    { label: 'Contact', type: 'link', href: '#' },
  ];

  return (
    <nav className="absolute inset-x-0 top-0 mx-auto flex h-24 items-center justify-between bg-transparent px-6 py-4 lg:max-w-screen-xl">
      <Link href="/">
        <Image src={Logo} alt="Medway Online" width={200} height={200} className="mb-4 h-14 w-28" />
      </Link>
      <ul className="flex space-x-6 text-gray-600">
        {menuItems.map((item: any) => {
          if (item.type === 'button') {
            return (
              <li key={item.label} className="relative">
                <button className="flex items-center space-x-1" onClick={item.onClick}>
                  <span>{item.label}</span>
                  <Image
                    src={DropdownIcon}
                    alt="Explore"
                    className={`mt-0.5 w-3 ${dropdown === item.key ? 'rotate-180' : 'rotate-0'} duration-200`}
                  />
                </button>
                {item.dropdown && dropdown === item.key && (
                  <div className="absolute -left-10 top-full mt-2 w-40 bg-white shadow-lg">
                    <ul className="space-y-2 p-4">
                      {item.dropdown.map((subItem: any) => (
                        <li key={subItem.label}>
                          <Link
                            href={subItem.href}
                            className="block text-gray-600 hover:text-gray-900"
                            onClick={() => setDropdown(null)}
                          >
                            {subItem.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            );
          } else if (item.type === 'link') {
            return (
              <li key={item.label}>
                <Link href={item.href}>{item.label}</Link>
              </li>
            );
          }
        })}
      </ul>
      {/* Right Section */}
      <div className="flex items-center gap-x-4 text-center">
        <Image src={PlayStoreImage} alt="Google Play" width={200} height={200} className="h-7 w-20" />
        <Image src={AppStoreImage} alt="App Store" width={200} height={200} className="h-7 w-20" />
        <button className="rounded-md bg-primary-green px-4 py-1 text-center text-sm text-white hover:bg-teal-700">
          Login
        </button>
      </div>
      {dropdown === 'why-medway' && <WhyMedway setDropdown={setDropdown} />}
    </nav>
  );
};

export default Navbar;
