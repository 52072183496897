'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import DropdownIcon from '@/assets/Icons/Homepage/right-fill-dropdown-icon.svg';
import RightArrowIcon from '@/assets/Icons/Homepage/right-green-arrow.svg';
import YoutubeIcon from '@/assets/Icons/Homepage/youtube-color.svg';
import { batchData } from '@/constant/BatchData';
import { subjects, testData } from '@/constant/CommonData';
import { youtubeLink } from '@/constant/CommonLink';

const WhyMedway = ({ setDropdown }: any) => {
  const [contentActiveId, setContentActiveId] = useState('test');
  const [selectedId, setSelectedId] = useState('test');
  const [hoveredId, setHoveredId] = useState(null);

  const menuItems = [
    { id: 'test', name: 'Test' },
    { id: 'qbank', name: "Q' Bank" },
    { id: 'medwaySpecial', name: 'Medway Special' },
    { id: 'videos', name: 'Videos & Live Class Notes' },
    { id: 'notes', name: 'Notes' },
  ];

  const handleLinkClick = () => {
    setDropdown(null);
  };

  const renderContent: any = (id: string) => {
    switch (id) {
      case 'test':
        return (
          <div className="px-2 font-inter">
            {testData.map((data, index) => {
              return (
                <div key={index} className="grid grid-cols-3 gap-6 px-2">
                  {Object.entries(data).map(([key, section]: any) => (
                    <div key={key} className="mb-6">
                      <Link
                        href={`/tests/${section.title?.replace(/\s+/g, '-')?.toLowerCase()}`}
                        onClick={handleLinkClick}
                      >
                        <h4 className="text-primary font-semibold">{section.title}</h4>
                      </Link>
                      <ul className="mt-2 list-outside list-disc space-y-1 text-sm font-normal text-secondary-gray">
                        {section.pointers.map((point: string, i: number) => (
                          <li key={i} className="text-sm">
                            {point}
                          </li>
                        ))}
                      </ul>
                      <div className="mt-2 space-y-1 text-sm">
                        <p className="font-semibold text-gray-400">Subjects</p>
                        <div className="mt-2 columns-2">
                          <ul className="list-outside list-disc text-sm text-slate-gray">
                            {section.subjects.list.slice(0, 8).map((subject: any) => (
                              <li key={subject.id} className="break-inside-avoid">
                                {subject.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        );
      case 'qbank':
        return (
          <div className="grid grid-cols-1 gap-8 px-4 font-inter md:grid-cols-2">
            {/* RACE Section */}
            <div>
              <h4 className="text-lg font-bold text-secondary-green">RACE</h4>
              <p className="mb-4 text-sm text-secondary-green">Race</p>
              <div className="columns-2 space-y-4">
                {subjects.map((subject) => (
                  <p key={subject.id} className="break-inside-avoid text-xs text-gray-700">
                    {subject.name} <span className="text-gray-400">({subject.questionBanks} Q&apos;Banks)</span>
                  </p>
                ))}
              </div>
            </div>

            {/* PYQ Section */}
            <div>
              <h4 className="text-lg font-bold tracking-wide text-secondary-green">PYQ</h4>
              <p className="mb-4 text-sm text-secondary-green">Previous Year Question</p>
              <div className="columns-2 space-y-4">
                {subjects.map((subject) => (
                  <p key={subject.id} className="break-inside-avoid text-xs text-gray-700">
                    {subject.name} <span className="text-gray-400">({subject.questionBanks} Q&apos;Banks)</span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        );
      case 'videos':
        return (
          <div className="px-4 font-inter">
            <div className="text-primary flex gap-x-1 text-xs">
              <Image src={RightArrowIcon} alt="Explore " className="w-2" />
              <p className="text-slate-gray">Live Class Notes</p>
            </div>
            <div className="mt-2 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {batchData.liveClassNotes.images.map((video, index) => (
                <Link key={index} href={video.url} target="_blank" rel="noopener noreferrer" className="group block">
                  <div className="relative overflow-hidden rounded-lg shadow-md">
                    <Image
                      src={video.src}
                      alt={`Video ${index + 1}`}
                      className="size-full object-cover transition-transform group-hover:scale-105"
                    />
                  </div>
                </Link>
              ))}
            </div>
            <div className="mt-4 flex items-center text-xs">
              <p>Explore more videos on: </p>
              <Link
                href={`${youtubeLink}/${batchData.liveClassNotes.batch_youtube_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 flex items-center gap-x-1 rounded-full border border-primary-green px-2 py-1 hover:scale-105"
              >
                <Image src={YoutubeIcon} alt="@Medwayedu Channel" className="w-4" />
                <span className="">@Medwayedu/liveNotes</span>
              </Link>
            </div>
          </div>
        );
      case 'notes':
        return (
          <div className="px-4 font-inter">
            <div className="columns-2 space-y-4">
              {subjects.map((subject) => (
                <p key={subject.id} className="break-inside-avoid text-xs text-gray-700">
                  {subject.name} <span className="text-gray-400">({subject.questionBanks} Q&apos;Banks)</span>
                </p>
              ))}
            </div>
          </div>
        );
      default:
        return <div>Content not available</div>;
    }
  };

  return (
    <div className="absolute top-20 mt-2 w-[80rem] bg-white p-6 shadow-lg lg:max-w-screen-xl">
      <div className="flex">
        {/* Sidebar Menu */}
        <div className="w-1/5 border-r pr-4">
          <ul className="space-y-6">
            {menuItems.map((item: any) => (
              <li
                key={item.id}
                onMouseEnter={() => setHoveredId(item.id)}
                onMouseLeave={() => setHoveredId(null)}
                onClick={() => {
                  setSelectedId(item.id);
                  setContentActiveId(item.id);
                }}
              >
                <button
                  className={`flex w-full items-center justify-between border-l-2 py-1 pl-2 text-left font-inter text-sm font-medium tracking-tight
                  ${
                    hoveredId === item.id || selectedId === item.id
                      ? 'text-primary bg-custom-green-gradient hover:text-white'
                      : 'hover:text-primary text-gray-600'
                  } 
                  ${selectedId === item.id ? 'border-dark-green bg-custom-green-gradient text-white' : 'border-transparent'}`}
                >
                  {item.name}
                  <Image src={DropdownIcon} alt="Explore" className="mr-1 w-3 -rotate-90" />
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/* Dropdown Content */}
        <div className="w-3/4 pl-4">{renderContent(hoveredId || contentActiveId)}</div>
      </div>
    </div>
  );
};

export default WhyMedway;
