import(/* webpackMode: "eager" */ "/workspace/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Icons/common/facebook.svg");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Icons/common/instagram.svg");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Icons/common/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/Banner/bg-mini-test-banner.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/Banner/bg-mini-test-management-banner.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/Banner/bg-promo-banner.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/Banner/bg-subject-test-banner.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/Banner/bg-subject-test-management-banner.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/Faculty/ramanand-faculty.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/test-1.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/test-2.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Images/test-3.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Logo/app-store.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Logo/medway-logo.png");
;
import(/* webpackMode: "eager" */ "/workspace/assets/Logo/play-store.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/components/shared/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
